<template>
  <pagoda-detail-layout-v1
    content-title="客户详情页"
    back-text="返回主列表"
    @back="handleBackToList"
  >
    <template slot="content-form">
      <div class="customer-info">
        <el-image class="customer-avatar" :src="customerInfo.img"></el-image>
        <el-form
          class="el-form-reset pagoda-form-grid pagoda-form-grid--inline"
        >
          <el-form-item label="姓名" class="pagoda-form-grid-cell">
            <div class="customer-name">
              {{ customerInfo.name }}
              <span
                class="name-croWechat"
                v-if="customerInfo.customer_type === '2'"
              >
                @{{ customerInfo.corp_name }}
              </span>
              <span v-else>@微信</span>
            </div>
          </el-form-item>
          <el-form-item label="性别" class="pagoda-form-grid-cell">
            <p v-if="customerInfo.gender === '1'">男</p>
            <p v-else-if="customerInfo.gender === '2'">女</p>
            <p v-else>未知</p>
          </el-form-item>
          <el-form-item label="客户userid" class="pagoda-form-grid-cell">
            <p>{{ customerInfo.external_userid }}</p>
          </el-form-item>
          <el-form-item label="客户unionid" class="pagoda-form-grid-cell">
            <p>{{ customerInfo.customer_unionid }}</p>
          </el-form-item>
          <el-form-item label="已添加员工" class="pagoda-form-grid-cell">
            <div>
              <el-image
                class="customer-addMember"
                v-for="(item, index) in customerAddMember.qw_avatar"
                :key="index"
                :src="item"
              ></el-image>
              ，共{{ customerAddMember.total_member_num }}人
            </div>
          </el-form-item>
          <el-form-item label="跟进成员" class="pagoda-form-grid-cell">
            <p>{{ customerInfo.add_menber }}</p>
          </el-form-item>
          <el-form-item label="成员userid" class="pagoda-form-grid-cell">
            <p>{{ customerInfo.member_userid }}</p>
          </el-form-item>
          <el-form-item label="添加时间" class="pagoda-form-grid-cell">
            <p>{{ customerInfo.add_time }}</p>
          </el-form-item>
          <el-form-item label="数据库记录时间" class="pagoda-form-grid-cell">
            <p>{{ customerInfo.create_time }}</p>
          </el-form-item>
        </el-form>
      </div>
      <div class="customer-profile">
        <el-form
          class="el-form-reset pagoda-form-grid pagoda-form-grid--inline"
        >
          <el-form-item label="电话" class="pagoda-form-grid-cell">
            <p>{{ customerOtherInfo.tel }}</p>
          </el-form-item>
          <el-form-item label="年龄" class="pagoda-form-grid-cell">
            <p>{{ customerOtherInfo.age }}</p>
          </el-form-item>
          <el-form-item
            :label="customerOtherInfo.other_info.name"
            class="pagoda-form-grid-cell"
          >
            <p>{{ customerOtherInfo.other_info.value }}</p>
          </el-form-item>
        </el-form>
      </div>
      <div class="customer-labels">
        <el-form
          class="el-form-reset pagoda-form-grid pagoda-form-grid--inline"
        >
          <el-form-item label="标签" width="100%" class="pagoda-form-grid-cell">
            <div
              class="customer-label"
              v-for="(item, index) in customerLabel"
              :key="index"
            >
              {{ item }}
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="customer-detail">
        <el-tabs class="pagoda-form-table-layout-tab" v-model="currentTab">
          <el-tab-pane label="客户动态" name="trend"></el-tab-pane>
        </el-tabs>
        <div class="pagoda-form-table-layout-con">
          <!-- 客户动态 -->
          <div v-if="currentTab === 'trend'">
            <el-table
              ref="table"
              :data="customerNews"
              v-loading="isTableLoading"
              element-loading-background="rgba(0, 0, 0, 0)"
              tooltip-effect="light"
            >
              <el-table-column
                prop="type"
                width="110"
                label="操作"
              ></el-table-column>
              <el-table-column
                prop="content"
                label="具体动态"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="create_time"
                width="150"
                label="时间"
                show-overflow-tooltip
              ></el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
              layout="total, sizes, prev, pager, next, jumper"
              v-show="totalDataNum !== 0"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              :current-page="pageNumber"
              :total="totalDataNum"
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </template>
  </pagoda-detail-layout-v1>
</template>

<script>
import http from '@/services/api/customer'
export default {
  data() {
    return {
      formData: {
        page: 1,
        page_size: 10
      },
      customerInfo: {},
      customerAddMember: {},
      customerOtherInfo: {
        other_info: {}
      },
      customerLabel: [],
      currentTab: 'trend',
      isTableLoading: false,
      labelList: [],
      customerNews: [],
      totalDataNum: 0,
      pageSize: 10,
      pageNumber: 1
    }
  },
  methods: {
    getCustomerInfo(params) {
      http
        .getCustomerInfo(params)
        .then((res) => {
          this.customerInfo = res.data || {}
        })
        .catch(() => {})
    },
    getCustomerAddMember(params) {
      http
        .getCustomerAddMember(params)
        .then((res) => {
          this.customerAddMember = res.data || {}
        })
        .catch(() => {})
    },
    getCustomerOtherInfo(params) {
      http
        .getCustomerOtherInfo(params)
        .then((res) => {
          this.customerOtherInfo = res.data || {}
        })
        .catch(() => {})
    },
    getCustomerLabel(params) {
      http
        .getCustomerLabel(params)
        .then((res) => {
          this.customerLabel = res.data || {}
        })
        .catch(() => {})
    },
    getCustomerNews() {
      const params = this.formData
      http
        .getCustomerNews(params)
        .then((res) => {
          this.customerNews = res.data.list || []
          this.totalDataNum = res.data.total_num || 0
        })
        .catch(() => {})
    },
    // pageSize 改变时会触发
    handleSizeChange(val) {
      this.pageSize = val
      this.formData.page_size = val
      this.refreshTableData()
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.pageNumber = val
      this.formData.page = val
      this.getCustomerNews()
    },
    // 刷新表格数据，重置回第一页
    refreshTableData() {
      this.pageNumber = 1
      this.formData.page = 1
      this.getCustomerNews()
    },
    handleBackToList() {
      this.$router.push('/customerManage')
    }
  },
  created() {
    this.queryObj = this.$route.query
    this.formData.id = this.queryObj.id
    this.getCustomerNews()
    this.getCustomerInfo(this.queryObj)
    this.getCustomerAddMember(this.queryObj)
    this.getCustomerOtherInfo(this.queryObj)
    this.getCustomerLabel(this.queryObj)
  }
}
</script>
<style lang="less">
.customer-info {
  padding: 10px 0 0 120px;
  position: relative;
  .customer-avatar {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100px;
    height: 100px;
  }
}
.customer-label {
  display: inline-block;
  background-color: #00c587;
  color: #fff;
  border-radius: 3px;
  padding: 3px 10px;
  margin: 0 5px 5px;
  cursor: pointer;
}
.customer-profile {
  padding-top: 10px;
  border-top: 1px solid #e1e6eb;
  border-bottom: 1px solid #e1e6eb;
}
.customer-labels {
  padding-top: 10px;
  .el-form-item__content {
    width: 100%;
  }
}
.customer-addMember {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}
.customer-name span {
  color: #07c160;
}
.customer-name .name-croWechat {
  color: #e98839;
}
</style>
